import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const Product = ({ data, currencysymbol, currencysymbolcustomer }) => {
    const facilityQrMenu = useSelector((state) => state.facilityQrMenu);

    return (
        <Link
            to={`product/${data.idproductmenu}`}
            key={data.idproductmenu}
            className="flex justify-between border-2 bg-white gap-1 rounded"
            id={data.name}
        >
            <div className="w-[40%] h-32">
                <img
                    src={`https://cdn.vevez.com/images/${data.mediaurl}_240.jpg`}
                    alt={data.name}
                    className="rounded-l w-full h-full object-cover"
                />
            </div>
            <div className="w-[60%] px-2 pt-1">
                <div>
                    <div className="font-semibold text-gray-500">{data.name}</div>
                    <span className="text-red-500 font-semibold text-sm">
                        {facilityQrMenu.formdata['WebMenu.ClickForMoreInformationAndOnlineOrdering'] ??
                            'Daha fazla bilgi ve sipariş için tıkla!'}
                    </span>
                </div>
                <div className="text-right">
                    <strong>
                        {data.price.toFixed(2)} {currencysymbol}
                    </strong>
                    <div className="text-sm">
                        ( {data.pricecustomer.toFixed(2)} {currencysymbolcustomer} )
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default Product;

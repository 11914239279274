import React from 'react';

const ModalItem = ({ value, name, subname, select, setSelect }) => (
    <div
        className={`${
            select === value ? 'bg-red-500 text-white' : 'bg-white'
        } flex flex-col w-full p-4 text-black rounded cursor-pointer shadow hover:bg-red-500 hover:text-white`}
        role="button" // Klavye kullanıcıları için rol ekledik
        tabIndex="0" // Elemanın odaklanabilir olması için tabIndex ekledik
        onClick={() => setSelect(value)}
        onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
                setSelect(value);
            }
        }} // Klavye kullanıcıları için Enter ve Boşluk tuşu ile çalışması sağlandı
    >
        <span className="font-semibold">{name}</span>
        <span>{subname}</span>
    </div>
);

export default ModalItem;

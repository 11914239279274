import React from 'react';
import { useSelector } from 'react-redux';

const Portion = ({ portion, index }) => {
    const currenctCurrency = useSelector((state) => state.currency.currenctCurrency);

    return (
        <div className={`${index === 0 ? 'border-red-500' : 'border-gray-400'} flex justify-between border-2 bg-white gap-1 rounded`}>
            <div className="w-[40%] h-32">
                <img
                    src={`https://cdn.vevez.com/images/${portion?.mediaurl}_240.jpg`}
                    alt={portion?.name}
                    className="rounded-l w-full h-full object-cover"
                />
            </div>
            <div className="flex w-[60%] flex-col justify-between flex-auto m-2">
                <div>
                    <div className="font-semibold">{portion?.name}</div>
                    <p className="text-gray-700 text-base">{portion?.description}</p>
                </div>
                <div className="text-right">
                    <strong>
                        {portion.price?.toFixed(2)} {portion.currencysymbol}
                    </strong>
                    <p className="text-sm">
                        ( {portion.pricecustomer?.toFixed(2)} {portion.currencysymbolcustomer} )
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Portion;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../layout/Loading';
import { facilityQRMenuGetDetail } from '../../store/slices/facilityMenuSlice';
import Portion from './Portion';

const ProductDetail = ({ data, loading }) => {
    const dispatch = useDispatch();

    const [description, setDescription] = useState('');
    const [portions, setPortions] = useState([]);

    const facilityQrMenu = useSelector((state) => state.facilityQrMenu);
    const currenctLanguage = useSelector((state) => state.language.currenctLanguage);
    const currenctCurrency = useSelector((state) => state.currency.currenctCurrency);

    const fullmediaurl = `https://cdn.vevez.com/images/${data.mediaurl}_1920.jpg`;

    function addToBasket() {
        // Kullanıcı cihazını belirleme
        const userAgent = navigator.userAgent;
        const linkToAndroidApp = 'https://play.google.com/store/apps/details?id=com.vevez.vevezmobile';
        const linkToIOSApp = 'https://apps.apple.com/us/app/vevez/id6449850843';

        if (userAgent.match(/Android/i)) {
            // Kullanıcı Android'den girdi, Android uygulama indirme linkine yönlendir
            window.location.href = linkToAndroidApp;
        } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
            // Kullanıcı iOS'tan girdi, iOS uygulama indirme linkine yönlendir
            window.location.href = linkToIOSApp;
        } else {
            // Diğer platformlardan giriş yapıldı
        }
    }

    useEffect(() => {
        const idproductmenu = data.idproductmenu;
        const qr = facilityQrMenu.qr;
        const idlanguage = currenctLanguage.idlanguage;
        const idcurrency = currenctCurrency?.iddefinition;
        async function getProductDescription() {
            const productDetail = await dispatch(
                facilityQRMenuGetDetail({
                    qr,
                    idlanguage: idlanguage || 0,
                    idproductmenu,
                    idcurrency: idcurrency || 0
                })
            );
            setDescription(productDetail.payload.data.description ?? '');
            setPortions(productDetail.payload.data.portions);
        }
        if (idproductmenu && qr && idlanguage && idcurrency) {
            getProductDescription();
        }
    }, [data.idproductmenu, facilityQrMenu.qr, currenctLanguage.idlanguage, currenctCurrency?.iddefinition]);

    return (
        <div>
            {loading && <Loading />}

            <div>
                <div className="w-full">
                    <img src={fullmediaurl} alt={data.name} className="w-full h-[50vh] object-cover" />
                </div>
                <div className="bg-white rounded">
                    <div className="p-4">
                        <h2 className="font-semibold">{facilityQrMenu.formdata['WebMenu.Name'] ?? 'Name'}</h2>
                        <p>{data.name}</p>
                    </div>
                    <div className="p-4">
                        <h2 className="font-semibold">{facilityQrMenu.formdata['WebMenu.Description'] ?? 'Description'}</h2>
                        <p>{description}</p>
                    </div>
                </div>
                {/* <div className="p-4">
                    <h2 className="font-semibold">{facilityQrMenu.formdata['WebMenu.Price'] ?? 'Price'}</h2>

                    <strong>
                        {data.price?.toFixed(2)} {currencysymbol}
                    </strong>
                    <div className="text-xs">
                        ( {data.pricecustomer?.toFixed(2)} {currencysymbolcustomer} )
                    </div>
                </div> */}
                <div className="w-full p-4">
                    <div className="pt-4 pb-4 border-gray-400 bg-white rounded flex flex-col justify-between leading-normal">
                        <div className="pl-4 pb-4">
                            <div className="text-gray-900 font-bold text-xl mb-2">
                                {facilityQrMenu.formdata['WebMenu.Portion'] ?? 'Portion'}
                            </div>
                        </div>
                        <div className="flex flex-col gap-4">
                            {portions.map((portion, index) => (
                                <Portion portion={portion} key={`portions_${index}`} index={index} />
                            ))}
                        </div>
                    </div>
                </div>
                <div className="p-4">
                    <button className="bg-red-500 w-full p-3 rounded-full text-white font-bold" type="button" onClick={() => addToBasket()}>
                        {facilityQrMenu.formdata['WebMenu.Order'] ?? 'Add to Basket'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ProductDetail;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLanguages, setCurrentLanguage } from '../../../store/slices/LanguageSlice';
import { setCurrentCurrency } from '../../../store/slices/CurrencySlice';
import { facilityQRMenuGet } from '../../../store/slices/facilityMenuSlice';
import { useParams } from 'react-router-dom';
import ModalSearch from '../../../ui-component/ModalSearch/ModalSearch';

const Language = () => {
    const { qrCode } = useParams();
    const dispatch = useDispatch();
    const language = useSelector((state) => state.language);
    const currency = useSelector((state) => state.currency);
    const facilityQrMenu = useSelector((state) => state.facilityQrMenu);

    const idulng = language?.currenctLanguage?.idlanguage;
    const iddefinition = currency?.currenctCurrency?.iddefinition;

    useEffect(() => {
        if (language.languages.length === 0 && !language.loading) {
            dispatch(fetchLanguages());
        }

        if (idulng !== facilityQrMenu?.idlanguage || iddefinition !== facilityQrMenu?.idcurrency) {
            dispatch(
                facilityQRMenuGet({
                    qr: qrCode,
                    idlanguage: idulng || 0,
                    idcurrency: iddefinition || 0
                })
            );
            dispatch(fetchLanguages(idulng));
        }
    }, [idulng, iddefinition]);

    useEffect(() => {
        if (!idulng && facilityQrMenu?.idlanguage) {
            dispatch(
                setCurrentLanguage({
                    idlanguage: facilityQrMenu?.idlanguage
                })
            );
        }
        if (!iddefinition && facilityQrMenu?.idcurrency) {
            dispatch(
                setCurrentCurrency({
                    iddefinition: facilityQrMenu?.idcurrency,
                    symbol: facilityQrMenu?.currencysymbolcustomer
                })
            );
        }
    }, [facilityQrMenu?.idlanguage, facilityQrMenu?.idcurrency]);

    return (
        <ModalSearch
            titleKey="ChooseYourLanguage"
            data={language.languages.map((x) => ({
                value: x.idlanguage,
                name: x.nameinown,
                subname: `${x.name} (${x.code.toUpperCase()})`
            }))}
            value={language?.languages?.find((x) => x.idlanguage === language?.currenctLanguage?.idlanguage)?.name}
            setValue={(value) => {
                const selectedLang = language.languages.find((x) => x.idlanguage === value);
                dispatch(setCurrentLanguage(selectedLang));
            }}
        />
    );
};

export default Language;

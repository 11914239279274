import React from 'react';
import { useSelector } from 'react-redux';

const Portion = ({ portion, index }) => {
    const currenctCurrency = useSelector((state) => state.currency.currenctCurrency);

    return (
        <div className="flex">
            {/* <div
                className="w-[240px] h-auto bg-no-repeat object-cover woverflow-hidden"
                style={{ backgroundImage: `url(https://cdn.vevez.com/images/${portion?.mediaurl}_240.jpg)` }}
                title={portion?.name}
            /> */}
            <div
                className={`${
                    index === 0 ? 'border-red-500' : 'border-gray-400'
                } flex-1 border p-4 flex flex-col justify-between leading-normal`}
            >
                <div className="mb-8">
                    <div className="text-gray-900 font-bold text-xl mb-2">{portion?.name}</div>
                    <p className="text-gray-700 text-base">{portion?.description}</p>
                </div>
                <div className="flex">
                    <div className="text-base ml-auto text-right">
                        <strong>
                            {portion.price?.toFixed(2)} {portion.currencysymbol}
                        </strong>
                        <p className="text-sm">
                            ({portion.pricecustomer?.toFixed(2)} {portion.currencysymbolcustomer})
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Portion;

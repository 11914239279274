import { Dialog } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import ModalItem from './ModalItem';
import { useSelector } from 'react-redux';

export default function ModalSearch({ titleKey, data, value, setValue }) {
    const [open, setOpen] = useState(false);
    const [select, setSelect] = useState();

    const [searchData, setSearchData] = useState([]);
    const [searchInput, setSearchInput] = useState('');

    const facilityQrMenu = useSelector((state) => state.facilityQrMenu);

    useEffect(() => {
        function searchData(searchTerm, data) {
            searchTerm = searchTerm?.toLowerCase();
            const filteredData = data?.filter((item) => item?.name?.toLowerCase()?.includes(searchTerm));
            return filteredData;
        }

        if (data) {
            let newData = data;
            if (searchInput.length >= 2) {
                newData = searchData(searchInput, data);
            }
            setSearchData(newData);
        }
    }, [data, searchInput]);

    return (
        <>
            <div>
                <button
                    type="button"
                    className="inline-flex min justify-between gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    onClick={() => setOpen(true)}
                >
                    <span>{value}</span>
                    <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                </button>
            </div>
            <Dialog open={open} onClose={setOpen} className="relative z-10">
                <Dialog.Backdrop
                    transition
                    className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                        <Dialog.Panel
                            transition
                            className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in my-8 w-full max-w-lg data-[closed]:scale-95"
                        >
                            <div className="bg-white px-4 pb-4 pt-5">
                                <div className="sm:flex sm:items-start">
                                    <div className="w-full mt-3 text-center">
                                        <Dialog.Title as="h2" className="text-2xl text-center font-semibold text-gray-900 mb-4">
                                            {facilityQrMenu.formdata[`WebMenu.${titleKey}`] ?? titleKey}
                                        </Dialog.Title>
                                        <div>
                                            <input
                                                className="w-full appearance-none border border-gray-500/75 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                type="text"
                                                placeholder={facilityQrMenu.formdata[`WebMenu.Search`] ?? 'Search'}
                                                value={searchInput}
                                                onChange={(event) => setSearchInput(event.target.value)}
                                            />
                                        </div>
                                        <div className="flex flex-col gap-2 mt-2 overflow-y-scroll bg-gray-50 h-96 p-4 rounded">
                                            {searchData.map((x) => (
                                                <ModalItem
                                                    value={x.value}
                                                    name={x.name}
                                                    subname={x.subname}
                                                    select={select}
                                                    setSelect={setSelect}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 flex flex-row-reverse gap-4">
                                <button
                                    type="button"
                                    onClick={() => {
                                        setOpen(false);
                                        setValue(select);
                                        setSearchInput('');
                                    }}
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-red-600 px-4 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-red-500"
                                >
                                    {facilityQrMenu.formdata[`WebMenu.Select`] ?? 'Select'}
                                </button>
                                <button
                                    type="button"
                                    data-autofocus
                                    onClick={() => {
                                        setOpen(false);
                                        setSelect();
                                        setSearchInput('');
                                    }}
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-4 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                >
                                    {facilityQrMenu.formdata[`WebMenu.Cancel`] ?? 'Select'}
                                </button>
                            </div>
                        </Dialog.Panel>
                    </div>
                </div>
            </Dialog>
        </>
    );
}
